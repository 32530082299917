import React from "react"
import ReactDOM from "react-dom"
import Media from "react-media"
import { HashRouter as Router, Route, Link } from "react-router-dom"
import ConversationList from "@/components/Messenger/ConversationList"
import Conversation from "@/components/Messenger/Conversation"
import PersonProfile from "@/components/Messenger/PersonProfile"
import TurbolinksHistoryMaintainer from "@/components/TurbolinksHistoryMaintainer"
import { jsonFetch } from "@/src/modules/fetch_helper"
import ActionCable from "@/channels/consumer"
import { castPerson } from "@/components/Messenger/util"

function fetchConversations(props) {
  let url = `${props.url}/conversations.json`
  if (props.relatedConversationId) {
    url += `?related_conversation_id=${props.relatedConversationId}`
  }
  return fetch(url)
    .then(body => body.json())
    .then(conversations =>
      conversations.map(c => mapConversationWithMessages(c, props))
    )
}

function renderMessageTemplate(url, conversationId, messageTemplateId) {
  return fetch(
    `${url}/messages/new?conversation_id=${conversationId}&message_template_id=${messageTemplateId}`
  ).then(body => body.text())
}

function buildDisplayStatus(timeAgo, status, errorCode, errorCodeExplanations) {
  if (status == "invalid_to_number") {
    return "Failed to send. Recipient's number is not valid."
  } else if (status == "undelivered") {
    return `Failed to send. ${errorCodeExplanations[errorCode]} (${errorCode})`
  } else {
    return timeAgo
  }
}

function mapMessage(m, person, props) {
  const avatar = m.direction == "outbound" ? m.user : person
  const date = moment(m.created_at).toDate()
  const timeAgo = moment(date).fromNow()
  const status = mapStatus(m.status)
  const displayStatus = buildDisplayStatus(
    timeAgo,
    status,
    m.external_error_code,
    props.errorCodeExplanations
  )
  return {
    created_at: m.created_at,
    id: m.id,
    avatar: avatar,
    position: m.direction == "outbound" ? "right" : "left",
    mediaUrls: m.media_urls,
    text: m.body,
    displayStatus: displayStatus,
    status: status,
    deleted: m.discarded_at,
    messageUrl: `${props.url}/messages/${m.id}`,
  }
}

function mapStatus(status) {
  switch (status) {
    case "_unread":
    case "sending":
      return "waiting"
    case "opt_in":
    case "opt_out":
    case "received":
    case "sent":
      return "sent"
    case "invalid_to_number":
      return "invalid_to_number"
    case "undelivered":
      return "undelivered"
    default:
      throw `Unhandled status '${status}'`
  }
}

function mapConversationWithMessages(c, props) {
  const currentUser = props.currentUser
  const copyOfPersonData = { ...c.person }
  const person = castPerson(copyOfPersonData)
  person.profileUrl = `/${props.url.split("/")[1]}/people_profiles/${person.id}`
  c.messages = c.messages.map(m => mapMessage(m, person, props))
  var subtitle = ""
  if (c.messages.length > 0) {
    subtitle = c.messages[c.messages.length - 1].text
  }
  const userSubscription = c.subscriptions.find(
    subscription => subscription.user_id == currentUser.id
  ) || { unread_messages_count: 0 }
  const title = [
    person.first_name,
    person.nickname ? `"${person.nickname}" ` : "",
    person.last_name,
  ].join(" ")
  return {
    id: c.id,
    communityName: c.community_name,
    title: title,
    subtitle: subtitle,
    date: c.updated_at,
    unread: userSubscription.unread_messages_count,
    messages: c.messages,
    recipientPhoneNumber: person.cell_phone,
    subscriptions: c.subscriptions,
    person: person,
    discarded_at: c.discarded_at,
    relatedConversations: (c.related_conversations || []).map(conversation =>
      mapConversationWithMessages(conversation, props)
    ),
  }
}

function appendMessageToConversation(conversations, conversationId, message) {
  const conversation = conversations.find(
    c => c.id.toString() == conversationId.toString()
  )
  if (!conversation.messages) {
    conversation.messages = []
  }
  conversation.messages.push(message)
  return conversations
}

class Messenger extends React.Component {
  state = {
    conversations: [],
    isDisconnected: true,
    isLoading: true,
  }

  _createSubscription() {
    const component = this
    this.subscription = ActionCable.subscriptions.create(this.channelParams, {
      connected() {
        console.log(this.identifier, "Connected")
        component.setState({ isDisconnected: false })
        component.refreshConversations()
      },

      disconnected() {
        console.log(this.identifier, "Disconnected")
        component.setState({ isDisconnected: true })
      },

      received(message) {
        console.log(this.identifier, "Received", message)
        component.loadConversation(message.conversation_id)
      },
    })
  }

  _ensureConnectedToSubscription() {
    if (this.state.isDisconnected) {
      console.log("[Messenger]", "creating subscription")
      if (this.subscription) {
        ActionCable.subscriptions.remove(this.subscription)
      }
      const identifier = JSON.stringify(this.channelParams)
      const existing = ActionCable.subscriptions.findAll(identifier)[0]
      if (existing) {
        this._useExistingSubscription()
      } else {
        this._createSubscription()
        this.subscriptionTimeout = setTimeout(
          this._ensureConnectedToSubscription.bind(this),
          3000
        )
      }
    }
  }

  get channelParams() {
    return { channel: "MessengerChannel", room: this.communitySlug }
  }

  _useExistingSubscription() {
    console.log("[Messenger]", "use existing subscription")
    this._createSubscription()
    ActionCable.subscriptions["confirmSubscription"](
      this.subscription.identifier
    )
    this.subscription.connected()
  }

  componentDidMount() {
    this._ensureConnectedToSubscription()
  }

  componentWillUnmount() {
    console.log("[Messenger] componentWillUnmount")
    clearTimeout(this.subscriptionTimeout)
    ActionCable.subscriptions.remove(this.subscription)
  }

  conversationById(id) {
    return this.state.conversations.find(c => c.id == id)
  }

  activeConversationId() {
    const conversationIdMatch = window.location.href.match(
      /conversation\/([0-9]+)/
    )
    return (
      (conversationIdMatch && conversationIdMatch[1]) ||
      this.props.conversationId
    )
  }

  activeConversation() {
    return this.conversationById(this.activeConversationId())
  }

  addConversation(conversation) {
    if (!this.state.conversations.find(c => c.id == conversation.id)) {
      this.setState({
        conversations: [conversation].concat(this.state.conversations),
      })
    }
  }

  insertConversation = conversation => {
    this.setState({
      conversations: this.insertConversationIntoArray(
        this.state.conversations,
        conversation
      ),
    })
  }

  insertConversationIntoArray(conversations, conversation) {
    if (conversation) {
      const index = conversations.findIndex(c => c.id == conversation.id)
      const isMissing = index == -1
      if (isMissing) {
        conversations = [conversation].concat(conversations)
      } else {
        conversations[index] = conversation
      }
    }
    return conversations
  }

  loadConversation = conversationId => {
    this.requestConversation(conversationId, "get")
  }

  markAsRead = conversationId => {
    if (this.conversationById(conversationId).unread) {
      this.requestConversation(conversationId, "put")
    }
  }

  requestConversation = (conversationId, httpMethod) => {
    jsonFetch(`${this.props.url}/conversations/${conversationId}`, {
      params: { related_conversation_id: this.props.relatedConversationId },
      method: httpMethod,
    })
      .then(body => body.json())
      .then(json => mapConversationWithMessages(json, this.props))
      .then(conversation => this.insertConversation(conversation))
  }

  showPendingMessage = (conversationId, text) => {
    const message = {
      mediaUrls: [],
      position: "right",
      type: "text",
      text: text,
      dateString: "sending...",
      status: "waiting",
    }
    const conversations = appendMessageToConversation(
      this.state.conversations,
      conversationId,
      message
    )
    this.setState({ conversations: conversations })
  }

  sendMessage = (conversationId, text) => {
    this.showPendingMessage(conversationId, text)
    const body = {
      activity_id: this.props.activityId,
      conversation_id: conversationId,
      text: text,
    }
    jsonFetch(`${this.props.url}/messages`, {
      method: "post",
      body: JSON.stringify(body),
    })
      .then(_ => this.loadConversation(conversationId))
      .then(_ => window.document.dispatchEvent(new Event("messenger.sent")))
  }

  updateReceiveNotificationsForConversation = (conversationId, value) => {
    this.updateSubscription(conversationId, "put", {
      subscription: { receive_notifications: value },
    })
  }

  subscribeToConversation = conversationId => {
    this.updateSubscription(conversationId, "post", {})
  }

  unsubscribeFromConversation = conversationId => {
    this.updateSubscription(conversationId, "delete", {})
  }

  deleteConversation = (conversationId, history) => {
    jsonFetch(`${this.props.url}/conversations/${conversationId}`, {
      method: "DELETE",
    }).then(() => {
      history.push("/conversations")
      this.removeConversationFromState(conversationId)
    })
  }

  removeConversationFromState = conversationId => {
    this.setState({
      conversations: this.state.conversations.filter(c => {
        return c.id != conversationId
      }),
    })
  }

  updateSubscription = (conversationId, method, params) => {
    jsonFetch(
      `${this.props.url}/conversations/${conversationId}/subscription`,
      {
        body: JSON.stringify(params),
        method: method,
      }
    ).then(_ => this.loadConversation(conversationId))
  }

  refreshConversations = () => {
    fetchConversations(this.props).then(conversations => {
      conversations = this.keepActiveConversationInArray(conversations)
      this.setState({ conversations, isLoading: false })
      if (this.activeConversationId()) {
        this.loadConversation(this.activeConversationId())
      }
    })
  }

  keepActiveConversationInArray(conversations) {
    return this.insertConversationIntoArray(
      conversations,
      this.activeConversation()
    )
  }

  startConversation = (event, history) => {
    jsonFetch(`${this.props.url}/conversations`, {
      body: JSON.stringify({ person_id: event.person.id }),
      method: "post",
    })
      .then(body => body.json())
      .then(json => mapConversationWithMessages(json, this.props))
      .then(c => {
        this.addConversation(c)
        history.push(`/conversation/${c.id}`)
      })
  }

  renderConversationList = props => {
    return (
      <div className="messenger__panel messenger__panel--conversations">
        <ConversationList
          {...props}
          currentUser={this.props.currentUser}
          searchUrl={this.props.searchUrl}
          conversations={this.state.conversations}
          startConversation={this.startConversation}
          readOnly={this.props.readOnly}
        />
      </div>
    )
  }

  renderConversation = props => {
    const conversations = this.state.conversations
    const conversationId = (
      this.props.conversationId || props.match.params.id
    ).toString()
    var conversation = conversations.find(c => {
      return c.id.toString() == conversationId
    })
    conversation = JSON.parse(JSON.stringify(conversation))
    const showProfilePanel = props.match.params.id
    const profilePanel = (
      <Media query={window.xlBreakpoint}>
        {matches =>
          matches ? null : (
            <div className="messenger__panel messenger__panel--profile">
              <PersonProfile
                url={`/${this.communitySlug}`}
                personId={conversation.person.id}
              />
            </div>
          )
        }
      </Media>
    )
    return (
      <React.Fragment>
        <div className="messenger__panel messenger__panel--conversation">
          <Conversation
            {...props}
            currentUser={this.props.currentUser}
            loadConversation={this.loadConversation}
            markAsRead={this.markAsRead}
            messageTemplates={this.props.messageTemplates}
            renderMessageTemplate={renderMessageTemplate}
            sendMessage={this.sendMessage}
            subscribeToConversation={this.subscribeToConversation}
            unsubscribeFromConversation={this.unsubscribeFromConversation}
            updateReceiveNotificationsForConversation={
              this.updateReceiveNotificationsForConversation
            }
            deleteConversation={this.deleteConversation}
            conversation={conversation}
            messageId={this.props.messageId}
            url={this.props.url}
            readOnly={this.props.readOnly}
          />
        </div>
        {showProfilePanel && profilePanel}
      </React.Fragment>
    )
  }

  get communitySlug() {
    return this.props.url.split("/")[1]
  }

  render() {
    if (this.state.isLoading) {
      return null
    } else if (this.props.conversationId) {
      return (
        <Media query={window.xlBreakpoint}>
          {media => (
            <Router>
              <div
                className={`messenger__panels messenger__panels--conversation-only messenger__panels--mobile-is-${media}`}
              >
                <Route render={this.renderConversation} />
              </div>
            </Router>
          )}
        </Media>
      )
    } else {
      return (
        <Media query={window.xlBreakpoint}>
          {media => (
            <Router>
              <Route
                render={props => (
                  <TurbolinksHistoryMaintainer location={props.location} />
                )}
              />
              <div
                className={`messenger__panels messenger__panels--mobile-is-${media}`}
              >
                <Route
                  exact={media}
                  path="/"
                  render={this.renderConversationList}
                />
                <Route
                  exact
                  path="/conversation/:id"
                  render={this.renderConversation}
                />
                <Route
                  exact
                  path="/conversation/:id/message/:messageId"
                  render={this.renderConversation}
                />
              </div>
            </Router>
          )}
        </Media>
      )
    }
  }
}

export default Messenger
