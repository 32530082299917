import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function NavLink(props) {
  return (
    <a
      className="nav-link {props.className}"
      href={props.href}
      onClick={props.onClick}
    >
      <FontAwesomeIcon className="align-middle fa-fw" icon={props.icon} />
      <span className="d-none d-xl-inline-block">{props.title}</span>
    </a>
  )
}
