import React from "react"
import ReactDOM from "react-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import NotificationSystem from "react-notification-system"
import ActionCable from "@/channels/consumer"

function MaybeLink(props) {
  if (props.href) {
    return (
      <a href={props.href} className={props.className}>
        {props.text}
      </a>
    )
  } else {
    return null
  }
}

window.notificationsSubscription = null

export default class MyComponent extends React.Component {
  constructor(props) {
    super(props)
    this.notificationSystem = React.createRef()
    this.store = sessionStorage
  }

  canUseExistingSubscription() {
    return (
      window.notificationsSubscription &&
      JSON.parse(window.notificationsSubscription.identifier).room ==
        this.props.communitySlug
    )
  }

  componentDidMount() {
    this.loadExistingNotifications()
    if (this.canUseExistingSubscription()) {
      window.notificationsSubscription.received = this._subscriptionFunctions().received
    } else {
      window.notificationsSubscription &&
        ActionCable.subscriptions.remove(window.notificationsSubscription)
      window.notificationsSubscription = this._createSubscription()
    }
  }

  storageKey(notificationId) {
    return `notification:id:${notificationId}`
  }

  addNotification = message => {
    const hasBeenDismissed = this.store.getItem(this.storageKey(message.id))
    const displayForSeconds = this._getDisplaySeconds(message)
    this._debug("addNotification", message, {
      hasBeenDismissed: hasBeenDismissed,
      displayForSeconds: displayForSeconds,
    })
    if (!hasBeenDismissed) {
      const notification = this.notificationSystem.current
      switch (message.event) {
        case "inbound_call":
          notification.addNotification({
            autoDismiss: displayForSeconds,
            children: this.renderInboundCallChildren(message),
            dismissible: "button",
            level: "info",
            notificationId: message.id,
            title: (
              <span>
                <span className="notification-title-icon">
                  <FontAwesomeIcon icon="phone" />
                </span>{" "}
                Incoming Call from {message.data.caller_description}
              </span>
            ),
            position: "bc",
            onRemove: this.onRemove,
          })
          break
        default:
          throw new Error(`Unknown notification: ${JSON.stringify(message)}`)
      }
    }
  }

  renderInboundCallChildren(message) {
    var leadSourceSection = ""
    if (message.data.lead_source_name) {
      leadSourceSection = (
        <div className="notification-section">
          <div className="notification-section__title">Lead Source</div>
          <div className="notification-section__detail">
            {message.data.lead_source_name}
          </div>
        </div>
      )
    }
    var callToAction = ""
    if (message.data.url) {
      callToAction = (
        <div className="notification-sections">
          <div className="notification-section notification-section--call-to-action">
            <MaybeLink
              href={message.data.url}
              text="View Profile"
              className="btn btn-success"
            />
          </div>
        </div>
      )
    }
    return (
      <div className="notification-body">
        <div className="notification-sections">
          <div className="notification-section">
            <div className="notification-section__title">To</div>
            <div className="notification-section__detail">
              {message.data.to_number}
            </div>
          </div>
          <div className="notification-section">
            <div className="notification-section__title">
              {this.props.location}
            </div>
            <div className="notification-section__detail">
              {message.data.community_name}
            </div>
          </div>
          {leadSourceSection}
        </div>
        {callToAction}
      </div>
    )
  }

  loadExistingNotifications() {
    JSON.parse(this.props.existingNotifications).forEach(n =>
      this.addNotification(n)
    )
  }

  onRemove = notification => {
    this._debug("rememberingExpiredNotification", notification)
    this.store.setItem(this.storageKey(notification.notificationId), true)
  }

  render() {
    return <NotificationSystem ref={this.notificationSystem} style={false} />
  }

  _createSubscription() {
    const room = this.props.communitySlug
    return ActionCable.subscriptions.create(
      { channel: "InboundCallNotificationsChannel", room: room },
      this._subscriptionFunctions()
    )
  }

  _getDisplaySeconds(message) {
    const maxDisplayTime = 30
    const secondsSinceCreation = moment
      .duration(moment().diff(moment(message.created_at)))
      .asSeconds()
    const displayForSeconds = maxDisplayTime - secondsSinceCreation
    return displayForSeconds
  }

  _subscriptionFunctions() {
    const component = this
    return {
      connected() {
        console.log(this.identifier, "Connected")
      },

      disconnected() {
        console.log(this.identifier, "Disconnected")
      },

      received(message) {
        console.log(this.identifier, "Received", message)
        component.addNotification(message)
      },
    }
  }

  _debug(...messages) {
    console.log("[InboundCallNotifications]", ...messages)
  }
}
