import React from "react"
import ReactDOM from "react-dom"

export default class PersonProfile extends React.Component {
  state = { content: "" }

  constructor(props) {
    super(props)
  }

  componentDidMount() {
    this.fetchProfile()
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.personId != this.props.personId) {
      this.setState({ content: "" })
      this.fetchProfile()
    }
  }

  get url() {
    return `${this.props.url}/people_profiles/${this.props.personId}`
  }

  fetchProfile() {
    fetch(this.url)
      .then(response => response.text())
      .then(body => this.setState({ content: body }))
      .then(() => window.applyGlobalJS($("body")))
  }

  render() {
    if (this.state.content.length) {
      return (
        <div className="messenger__person-profile">
          <span dangerouslySetInnerHTML={{ __html: this.state.content }} />
          <div className="messenger__person-profile-link">
            <a href={`${this.url}?redirect=true`}>View Full Profile</a>
          </div>
        </div>
      )
    } else {
      return null
    }
  }
}
