function _headers() {
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-CSRF-Token": $('meta[name="csrf-token"]').attr("content"),
  }
}

export function jsonFetch(url, options) {
  const mergedOptions = { ...{ headers: _headers() }, ...options }
  return fetch(url, mergedOptions)
}
