import React from "react"

export default class TurbolinksHistoryMaintainer extends React.Component {
  componentDidMount() {
    this.replaceState()
  }

  componentDidUpdate() {
    this.replaceState()
  }

  replaceState() {
    if (!window.history.state) {
      console.log(
        `[${this.constructor.name}]`,
        "replaceState",
        window.location.href
      )
      window.history.replaceState(
        { turbolinks: {} },
        window.document.title,
        window.location
      )
    }
  }

  render() {
    return null
  }
}
