import React from "react"

export default function Avatar(props) {
  const style = { borderColor: props.score_color }
  var identifier = (
    <div className={`avatar__initials score-badge`} style={style}>
      {props.initials}
    </div>
  )
  if (props.has_profile_picture) {
    identifier = (
      <img
        className={`avatar__picture score-badge`}
        loading="lazy"
        src={`${props.profileUrl}?profile_picture=true`}
        style={style}
      />
    )
  }
  return (
    <React.Fragment>
      {identifier}
      {props.unread > 0 && (
        <div className="avatar__count-badge">{props.unread}</div>
      )}
    </React.Fragment>
  )
}
