import React from "react"
import ReactDOM from "react-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { isMobile } from "react-device-detect"
import LongPress from "react-long"
import debounce from "debounce"
import NavLink from "./NavLink"

class GlobalSearchResults extends React.Component {
  state = { selected: 0 }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.resultHTML != this.props.resultHTML) {
      this.setState({ selected: 0 })
    }
  }

  handleSpecialKey = event => {
    event.preventDefault()
    switch (event.key) {
      case "Enter":
        this._openActiveResult({ newTab: event.ctrlKey })
        break
      case "ArrowUp":
        this._updateSelected(-1)
        break
      case "ArrowDown":
        this._updateSelected(1)
        break
    }
  }

  _updateSelected(delta) {
    if (this.items) {
      let newSelected = this.state.selected + delta
      if (newSelected > this.items.length - 1) {
        newSelected = this.items.length - 1
      }
      if (newSelected < 0) {
        newSelected = 0
      }
      this.setState({ selected: newSelected })
    }
  }

  _openActiveResult(options = {}) {
    const result = Array.from($(this.listRef).find(".js-list-result"))[
      this.state.selected
    ]
    if (result) {
      const opener = {
        true: () => {
          window.open(result.getAttribute("href"), "_blank")
        },
        false: () => {
          result.click()
        },
      }
      opener[options.newTab]()
    }
  }

  render() {
    const lis = Array.from($(this.props.resultHTML).find(".js-list-result"))
    if (lis.length) {
      this.items = lis.map((el, i) => {
        return (
          <GlobalSearchResult
            key={i}
            className={el.className}
            href={el.getAttribute("href")}
            html={el.innerHTML}
            selected={this.state.selected == i}
          />
        )
      })
      return (
        <div className="global-search__dropdown">
          <div className="global-search__results" ref={e => (this.listRef = e)}>
            {this.items}
          </div>
        </div>
      )
    } else if (!this.props.queryPresent) {
      return null
    } else {
      return (
        <div className="global-search__dropdown">
          <div
            className="global-search__results"
            dangerouslySetInnerHTML={{ __html: this.props.resultHTML }}
          />
        </div>
      )
    }
  }
}

function GlobalSearchResult(props) {
  let className = props.className
  if (props.selected) {
    className += " list-result--active"
  }
  return (
    <LongPress time={500} onLongPress={() => Turbolinks.visit(props.href)}>
      <a
        className={className}
        href={props.href}
        dangerouslySetInnerHTML={{ __html: props.html }}
      />
    </LongPress>
  )
}

class GlobalSearchField extends React.Component {
  focus() {
    this.inputRef.focus()
  }

  render() {
    const { isLoading, ...rest } = this.props
    return (
      <React.Fragment>
        <FontAwesomeIcon
          className="fa-w-16 global-search__search-icon"
          icon="search"
        />
        <input
          type="text"
          className="global-search__search-field"
          placeholder="Search..."
          value={this.props.value}
          ref={e => (this.inputRef = e)}
          {...rest}
        />
        {isLoading && <span className="global-search__spinner" />}
      </React.Fragment>
    )
  }
}

class GlobalSearch extends React.Component {
  initialState = {
    isLoading: false,
    mouseOver: false,
    query: "",
    searchValue: "",
    resultHTML: "",
  }

  constructor(props) {
    super(props)
    this.state = this.initialState
  }

  performSearch() {
    var url = new URL(this.props.url)
    url.searchParams.append("query", this.state.query)
    fetch(url, { headers: { Accept: "application/javascript" } })
      .then(res => res.text())
      .then(body => this.setState({ isLoading: false, resultHTML: body }))
  }

  search = e => {
    e.persist()
    this.setState({ isLoading: true, searchValue: e.target.value })
    this.innerSearch()
  }

  innerSearch = debounce(e => {
    if (this.state.query != this.state.searchValue) {
      this.setState({ query: this.state.searchValue })
      this.performSearch()
    }
  }, 500)

  handleKeyDown = e => {
    switch (event.key) {
      case "Enter":
      case "ArrowUp":
      case "ArrowDown":
        this.searchResults.handleSpecialKey(e)
        break
      case "Escape":
        this.closeSearch()
        break
    }
  }

  closeSearch() {
    this.setState(this.initialState)
  }

  handleBlur = event => {
    if (!isMobile) {
      if (!this.state.mouseOver) {
        this.closeSearch()
      }
    }
  }

  handleOnMouseOver = event => {
    this.setState({ mouseOver: true })
  }

  handleOnMouseLeave = event => {
    this.setState({ mouseOver: false })
    if (isMobile) {
      this.closeSearch()
    }
  }

  render() {
    return (
      <React.Fragment>
        <div
          onMouseOver={this.handleOnMouseOver}
          onBlur={this.handleBlur}
          onMouseLeave={this.handleOnMouseLeave}
        >
          <GlobalSearchField
            ref={e => (this.inputRef = e)}
            isLoading={this.state.isLoading}
            onChange={this.search}
            onKeyDown={this.handleKeyDown}
            value={this.state.searchValue}
          />
          <GlobalSearchResults
            ref={e => (this.searchResults = e)}
            resultHTML={this.state.resultHTML}
            queryPresent={!!this.state.query}
          />
        </div>
      </React.Fragment>
    )
  }
}

export default GlobalSearch
