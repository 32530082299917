import moment from "moment"

export function timeAgo(string) {
  return moment(string).fromNow()
}

export function timeAgoShort(string) {
  return timeAgo(string)
    .replace(/^an?/, "1")
    .replace(/few /, "")
    .replace(/years?/, "y")
    .replace(/months?/, "m")
    .replace(/weeks?/, "w")
    .replace(/days?/, "d")
    .replace(/hours?/, "h")
    .replace(/minutes?/, "m")
    .replace(/seconds?/, "s")
    .replace(/ ago$/, "")
    .replace(/ /, "")
}
