import "./../stylesheets/application.scss"

import "@/src/expoAppHelpers"
import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
Rails.start()
Turbolinks.start()
import "bootstrap"
import "./modalHandler"

function applyGlobalJS() {}
window.applyGlobalJS = applyGlobalJS
